import React from "react";
import "../css/Homepage.css";
import Github from "./Github.jsx";
import ComingSoon from "./ComingSoon.jsx";

export default function Homepage() {
  return (
    <>
      <ComingSoon />
      <div className="projectContainer"></div>
      <Github />
    </>
  );
}
