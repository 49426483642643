import React from "react";
import { Typography } from "@material-ui/core";

export default function ComingSoon() {
  return (
    <>
      <Typography className="commingSoon" variant="h1" component="h2">
        (COMING SOON)
      </Typography>
      <div style={{ height: "40px" }}></div>
      <Typography className="commingSoon" variant="h4" component="h5">
        While you wait checkout this:
      </Typography>
      <Typography className="commingSoon" variant="h5" component="h6">
        <a href="https://www.sortingvisualiser.max-hitchings.com/">
          Sorting Algorithm Visualizer
        </a>
      </Typography>
      <Typography className="commingSoon" variant="h4" component="h5">
        Or this:
      </Typography>
      <Typography className="commingSoon" variant="h5" component="h6">
        <a href="https://www.cipher.max-hitchings.com/">
          caeser cipher school project
        </a>
      </Typography>
    </>
  );
}
