import React from "react";
import Homepage from "./Homepage.jsx";
function App() {
  return (
    <>
      <Homepage />
    </>
  );
}

export default App;
