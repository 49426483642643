import React from "react";

export default function Github() {
  return (
    <>
      <h1 align="center" className="github-text-large">
        Max Hitchings
      </h1>
      <h3 align="center" className="github-text-small">
        🔭 I’m currently working on a{" "}
        <a
          href="https://github.com/Max-Hitchings/Blackjack-Web-App"
          target="_blank"
          rel="noopener"
        >
          blackjack web app
        </a>{" "}
        with ReactJS and ExpressJS
      </h3>
      <h3 align="center" className="github-text-small">
        🌱 I’m currently learning ReactJS and Django rest framework
      </h3>
      <h3 align="center" className="github-text-small">
        📺 What i do when i am not coding:
        <a
          href="https://github.com/Max-Hitchings/Max-Hitchings/blob/main/Netflix.csv"
          target="_blank"
        >
          Netflix
        </a>
      </h3>
      <div align="center">
        <img
          align="center"
          src="https://github-readme-stats.vercel.app/api/?username=Max-Hitchings&theme=tokyonight"
        />
      </div>
      <div align="center">
        <a href="https://github.com/Max-Hitchings/sorting_algorithm_visualizer">
          <img
            align="center"
            src="https://github-readme-stats.vercel.app/api/pin/?username=Max-Hitchings&repo=sorting_algorithm_visualizer&theme=tokyonight"
          />
        </a>
        <a href="https://github.com/Max-Hitchings/caesar-cipher-school-project">
          <img
            align="center"
            src="https://github-readme-stats.vercel.app/api/pin/?username=Max-Hitchings&repo=caesar-cipher-school-project&theme=tokyonight"
          />
        </a>
      </div>
    </>
  );
}
